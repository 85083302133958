<template>
  <div id="app-course-add" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="base">
        <el-form ref="form" label-width="120px">
          <el-form-item label="图书名称" :required="true">
            <el-input type="text"  v-model="course.name"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="课时数量" :required="true">
            <el-input type="number"  v-model="course.lessonNum"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="图书排序" :required="true">
            <el-input type="number"  v-model="course.sort"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="课程讲师">
            <el-select v-model="course.teacherIds" multiple placeholder="请选择" style="width: 100%">
              <el-option
                  v-for="teacher in teachers"
                  :key="teacher.id"
                  :label="teacher.name"
                  :value="teacher.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属科目">
            <el-select v-model="course.subjectIds" multiple placeholder="请选择" style="width: 100%">
              <el-option
                  v-for="subject in subjects"
                  :key="subject.id"
                  :label="subject.name"
                  :value="subject.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="首页推荐" style="display: none">
            <el-radio v-model="course.isRecommend" :label="false">否</el-radio>
            <el-radio v-model="course.isRecommend" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item label="所属年份">
            <el-radio v-for="year in years" v-model="course.yearId" :label="year.id">
              {{year.name}}
            </el-radio>
          </el-form-item>
          <el-form-item label="相关题型" v-show="isShowQuestionType">
            <el-radio v-for="questionType in questionTypes" v-model="course.typeId" :label="questionType.id">
              {{questionType.name}}
            </el-radio>
          </el-form-item>
          <upload-form-item ref="smallPicUrl"  @parentEvent="getSmallPicUrl"></upload-form-item>
          <upload-form-item ref="bigPicUrl"  @parentEvent="getBigPicUrl"></upload-form-item>
          <el-form-item label="图书介绍">
            <quill-editor class="editor" @focus="quill = this" v-model="course.content" :options="quillOption"></quill-editor>
            <div class="layui-input-block">
              <span style="color:#666;padding:5px;display: block;">图宽750、图高不限、单图不超500K</span>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="销售信息" name="sale">
        <el-form ref="form-sale" label-width="120px">
          <el-form-item label="市场价格" :required="true">
            <el-input type="number"  v-model="course.marketPrice"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="售卖价格" :required="true">
            <el-input type="number"  v-model="course.price"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="已售基数" :required="true">
            <el-input type="number"  v-model="course.buyNum"  placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="有效期至" :required="true">
            <el-date-picker
                v-model="course.expire"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="APP/网站上架">
            <el-radio v-model="course.isSale" :label="false">否</el-radio>
            <el-radio v-model="course.isSale" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item label="小程序上架">
            <el-radio v-model="course.isMpSale" :label="false">否</el-radio>
            <el-radio v-model="course.isMpSale" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item label="热门课推荐">
            <el-radio v-model="course.isHotRecommend" :label="false">否</el-radio>
            <el-radio v-model="course.isHotRecommend" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item label="免费课推荐">
            <el-radio v-model="course.isFreeRecommend" :label="false">否</el-radio>
            <el-radio v-model="course.isFreeRecommend" :label="true">是</el-radio>
          </el-form-item>
          <el-form-item style="display: none">
            <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {CourseCategoryEnum, getLocalProjectId, request, TipEnum, UrlEnum} from "../../../../public/js/common-vue";
import UploadFormItem from "@/components/UploadFormItem";
import {quillEditor} from "vue-quill-editor";
import quillConfig from "@/assets/js/quill-config";
import Loading from "@/components/Loading";

export default {
  name: "BookSave",
  data() {
    return {
      loading: false,
      id:0,
      activeName: 'base',
      years:[],
      isShowQuestionType:request("questionType") == 'true' ? true : false,
      teachers:[],
      questionTypes:[],
      subjects:[],
      quillOption: quillConfig,
      course: {
        name: '',
        sort:'',
        lessonNum:'',
        isRecommend:false,
        yearId:'',
        teacherIds:[],
        subjectIds:[],
        smallPicUrl: '',
        bigPicUrl: '',
        marketPrice:'',
        price:'',
        expire:'',
        buyNum:0,
        isSale:false,
        projectId:getLocalProjectId(),
        categoryId:parseInt(request("categoryId")),
        assistantId:0,
        isHotRecommend:false,
        isFreeRecommend:false,
        groupPicUrl: '',
        openChatGroup:false,
        nicknamePrefix:'',
        maxUserNum:0,
        isPost:true,
        content:'',
        isMpSale:false
      }
    }
  },
  methods:{
    initData(){
      this.$http({
        method: "get",
        url: UrlEnum.COURSE + "/" + request('id'),
      }).then((res) => {
        this.course = res.data;
        this.course.expire = new Date(this.course.expire).format("yyyy-MM-dd");
        this.$refs.smallPicUrl.init("图书小图",true,'309 x 233',this.course.smallPicUrl);
        this.$refs.bigPicUrl.init("图书大图",true,'828 x 467',this.course.bigPicUrl);
        console.log(this.course);
        this.getYears(); //需要categoryId
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getYears(){
      this.$http({
        method: "get",
        url: UrlEnum.YEARS+"?categoryId="+this.course.categoryId+"&projectId="+getLocalProjectId(),
      }).then((res) => {
        this.years = res.data.list;
        this.course.yearId = this.years[0].id;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getQuestionTypes(){
      this.$http({
        method: "get",
        url: UrlEnum.QUESTION_TYPE + '?page=1&size=100&ids=1,2,3',
      }).then((res) => {
        this.questionTypes = res.data.list;
        this.course.typeId = this.questionTypes[0].id;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getTeachers(){
      this.$http({
        method: "get",
        url: UrlEnum.TEACHER + '?page=1&size=100',
      }).then((res) => {
        this.teachers = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getSubjects(){
      this.$http({
        method: "get",
        url: UrlEnum.SUBJECT + '?page=1&size=100&projectId='+getLocalProjectId()+'&parentId=0',
      }).then((res) => {
        this.subjects = res.data.list;
      }).catch((res) => {
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    getSmallPicUrl(data){
      this.course.smallPicUrl = data;
    },
    getBigPicUrl(data){
      this.course.bigPicUrl = data;
    },
    checkForm() {
      if (this.course.name == '') {
        this.showMsgError("请输入 基本信息->图书名称");
        return false;
      }
      if(this.course.lessonNum == ''){
        this.showMsgError("请输入 基本信息->课时数量");
        return false;
      }
      if(this.course.sort === ''){
        this.showMsgError("请输入 基本信息->图书排序");
        return false;
      }
      if(this.course.smallPicUrl == ''){
        this.showMsgError("请上传 基本信息->图书小图");
        return false;
      }
      if(this.course.bigPicUrl == ''){
        this.showMsgError('请上传 基本信息->图书大图');
        return false;
      }
      if(this.course.marketPrice === ''){
        this.showMsgError('请输入 销售信息->市场价格');
        return false;
      }
      if(this.course.price === ''){
        this.showMsgError('请输入 销售信息->售卖价格');
        return false;
      }
      if(this.course.buyNum === ''){
        this.showMsgError('请输入 销售信息->已售基数');
        return false;
      }
      if(this.course.expire == ''){
        this.showMsgError('请输入 销售信息->有效期');
        return false;
      }
      return true;
    },
    submit(){
      if(this.checkForm() == false){
        return;
      }
      this.course.expire = new Date(this.course.expire).valueOf();
      if(this.course.majorIds == null){
        this.course.majorIds = [];
      }
      if(this.course.teacherIds == null){
        this.course.teacherIds = [];
      }
      if(this.course.subjectIds == null){
        this.course.subjectIds = [];
      }
      this.course.startTime = this.moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.course.endTime = this.moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.course.isBranchShare = false;
      let url = UrlEnum.COURSE;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      console.log(this.course);
      this.loading = true;
      this.$http({
        method: method,
        url: url,
        data: this.course,
      })
          .then((res) => {
            this.loading = false;
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            setTimeout(function () {
              window.parent.postMessage({
                msg: '',
                key: 'refresh'
              }, '*')
            }, 800);
          })
          .catch((res) => {
            this.loading=false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    }
  },
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
    else{
      this.$refs.smallPicUrl.init("图书小图",true,'309 x 233',this.course.smallPicUrl);
      this.$refs.bigPicUrl.init("图书大图",true,'828 x 467',this.course.bigPicUrl);
      this.getYears(); //需要categoryId
    }

    this.getQuestionTypes();
    this.getTeachers();
    this.getSubjects();
  },
  components: {Loading, UploadFormItem,quillEditor},
}
</script>

<style scoped>
@import "../../../../static/css/add.css";
@import "../../../../static/css/editor.css";
.editor{
  height: 500px;
  margin-bottom: 90px;
}
</style>
